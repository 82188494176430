import React, { useState } from "react";
import {
  Alert,
  Row,
  Col,
  Divider,
  Card,
  Select,
  Descriptions,
  Typography,
} from "antd";

const { Paragraph, Text, Title } = Typography;
const Option = Select.Option;

export const data = [
  {
    index: 0,
    title: "Exogeen alkali",
    item1: "Melk-alkali syndroom, ernstige hypercalciëmie",
    item2:
      "Citraat: oplosmiddel bloedproducten, wordt omgezet in HCO₃, bij massale transfusie",
  },
  {
    index: 1,
    title: "Chloride-resistent (urine Cl > 40 mmol/l)",
    item1: "Diuretica",
    item2: "Hypomagnesiëmie",
    item3: "Ernstige hypercalciëmie",
    item4: "Hypokaliëmie",
    item5: "Laxantia misbruik (laag urine K⁺)",
    item6:
      "Syndroom van Gitelman (laag/normale bloeddruk, hoog urine K⁺ laag urine Ca⁺⁺)",
    item7:
      "Syndroom van Bartter (laag/normale bloeddruk hoog urine K⁺ normaal/hoog urine Ca⁺⁺",
    item8:
      "Reëel of ogenschijnlijk overschot aan mineralocorticoïden (hoge bloeddruk, laag calium): - hyperaldosteronisme, - Cushing, - Glycyrrizinezuur (drop, zoethout), - Syndroom van Liddle",
  },
  {
    index: 2,
    title: "Chloride-resistent (urine Cl > 25 mmol/l)",
    item1: "Braken",
    item2:
      "(diuretica: wel hoog urine Cl maar bij staken wel chloride-responsief)",
  },
];

export default function SelectTableDifferentiaalDiagnoseMetaboleAlkalose() {
  const [current, setCurrent] = useState(0);
  return (
    <>
      <Card>
        <Row>
          <Col xs={24}>
            <Title level={4}>Selecteer:</Title>
            <Select
              size="large"
              style={{ width: "100%", marginTop: "8px" }}
              onChange={setCurrent}
              showSearch
              autoFocus
              defaultValue="Exogeen alkali"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {data.map((item) => (
                <Option value={item.index} key={item.title} title={item.title}>
                  {item.title}
                </Option>
              ))}
            </Select>
            {data[current].alert ? (
              <Alert
                message={data[current].alert}
                type="info"
                showIcon
                style={{ margin: "8px 0" }}
              />
            ) : null}
          </Col>
          <Col xs={24}>
            <Divider />
            <Title level={4}>Informatie:</Title>
            <Descriptions
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              bordered
              size="small"
              style={{ background: "white", marginTop: "16px" }}
            >
              <Descriptions.Item label={data[current].item1} />
              <Descriptions.Item label={data[current].item2} />
              {data[current].item3 ? (
                <Descriptions.Item label={data[current].item3} />
              ) : null}
              {data[current].item4 ? (
                <Descriptions.Item label={data[current].item4} />
              ) : null}
              {data[current].item5 ? (
                <Descriptions.Item label={data[current].item5} />
              ) : null}
              {data[current].item6 ? (
                <Descriptions.Item label={data[current].item6} />
              ) : null}
              {data[current].item7 ? (
                <Descriptions.Item label={data[current].item7} />
              ) : null}
              {data[current].item8 ? (
                <Descriptions.Item label={data[current].item8} />
              ) : null}
            </Descriptions>
          </Col>
        </Row>
      </Card>
    </>
  );
}
